import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons"

export default function CallButton(){
    return(
        <>
        <a  href="tel:+16199270588" name="Call Now!"><FontAwesomeIcon icon={faPhone} className="phone-icon" /></a>


        </>
    )
}