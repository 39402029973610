import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function About() {
  return (
    <>
      <Card
        border="light"
        className="w-100  text-white"
        bg="dark"
        style={{ padding: "50px 30px 100px 30px" }}
      >
        <Card.Body>
          <Card.Title className="subheader py-3">What We Do</Card.Title>
          <Card.Text>
            We cover Ottawa and all surrounding areas. Locks Pro CA offers
            the fastest automotive, residential, and commercial locksmith
            services in the area. Our team are a phone call away, anytime and
            anywhere. We guarantee you a full excellent service and we're
            responsible for any inconvenience.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card
        border="light"
        className="w-100 "
        bg="light"
        style={{ padding: "50px 30px 100px 30px" }}
      >
        <Card.Body>
          <Card.Title className="subheader py-3">What We Offer</Card.Title>
          <Card.Text>
            <p>
              <FontAwesomeIcon icon={faCheck} size="xl" className="px-3" style={{color: "#3374e6"}}/>
              We offer complete locksmith services 24/7.
            </p>
            <p>
            <FontAwesomeIcon icon={faCheck} size="xl" style={{color: "#3374e6"}} className="px-3"/>
              We guarantee customer satisfaction on any job we take.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheck} size="xl" className="px-3" style={{color: "#3374e6"}} />
              Our outstanding reputation for quality work has earned us the trust and recognition of thousands of happy customers.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheck} size="xl" className="px-3" style={{color: "#3374e6"}}/>
              We're fast in emergencies, give us a call and we'll be right there!
            </p>
            
          </Card.Text>
        </Card.Body>
      </Card>

    </>
  );
}
