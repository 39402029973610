import "./nav.css";
import React from "react";
import Logo from "../../images/Icon.png"
export default function Navigator() {
  return (
    <>
      <div className="Ad text-center">
        <p>Call Us Now On: +16199270588</p>
      </div>
      <nav className="logo text-white px-3">
        <span style={{fontFamily:"fantasy"}}>Locks Pro CA</span>
        <img src={Logo} alt=""width="60px" height="auto"/>
      </nav>
   
    </>
  );
}



