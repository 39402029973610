import React from 'react';
import {Card,Button} from 'react-bootstrap';
import LandingImg from "../images/landing.jpeg"

export default function Landing() {
  return (
    <Card className="text-white landing-card" style={{border:"none"}}>
      <Card.Img className='border-none landing-img' src={LandingImg} alt="Card image" />
      <Card.ImgOverlay className='text-md-center mx-md-3 mx-lg-5 card-overlay'>

        <div className='text-overlay'>
        <Card.Title className='mt-3 fs-1' >Locks Pro CA</Card.Title>
        <Card.Text className='mt-3' >
        Our locksmith solutions are tailored to meet the distinct requirements of each client, spanning Ottawa and its neighboring regions. Our team of skilled technicians is ready & available to offer assistance round the clock. As a trusted local establishment, we operate with full licensure and insurance coverage.
        </Card.Text>
        <Card.Text className='d-md-none'>Give us a call on +16199270588 and we'll be right at your doorstep! </Card.Text>
          <Button className='d-none d-md-inline-flex  button-call ' href="tel:+16199270588" >Call Now!</Button>
          </div>
        
      </Card.ImgOverlay>
    </Card>
  );
}

